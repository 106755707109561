import React from "react"
import './Panel.css'
import ShowCards from "./ShowCards";
import Login from "./Login"
import UserCenter from "./UserCenter";
import {subscribe, getGlobalState, setGlobalState} from "./GlobalState";
import Redeem from "./Redeem";
import queryString from "query-string";
import Draw from "./Draw"
import Cards from "./Cards";

class Panel extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            access:getGlobalState('access')?getGlobalState('access'):0,
            panelShow: getGlobalState('panelShow')?getGlobalState('panelShow'):'login'
        }
        //eslint-disable-next-line no-restricted-globals
        let query = location.search
        const parsed = queryString.parse(query);
        if(parsed.showRedeem){
            this.onShowRedeem()
        }
    }

    onShowRedeem(){
        setGlobalState('panelShow','showRedeem')
    }

    updateAccess(value){
        this.setState({
            access:value
        })
    }
    updatePanel(value){
        this.setState({
            panelShow:value
        })
    }

    render() {
        subscribe('access',this.updateAccess.bind(this))
        subscribe('panelShow',this.updatePanel.bind(this))
        let access = this.state.access
        let panelShow = this.state.panelShow
        let main
        // console.log(access)
        if(access==="0" || access===0 || !access){
            if(panelShow==='showRedeem'){
                main = <Redeem/>
            }else{
                main = <Login />
            }

        }else{
            if(panelShow==='login'||panelShow==='userCenter'){
                main = <UserCenter/>
            }
            if(panelShow==='showCards'||panelShow==='cards'){
                main = <ShowCards onClickCards={this.onClickCards.bind(this)}/>
            }
            if(panelShow==='redeem'){
                main = <Redeem/>
            }
            if(panelShow==='draw'){
                main = <Draw/>
            }
            if(panelShow==='drawCards'){
                main = <Cards/>
            }
            if(panelShow==='showRedeem'){
                main = <Redeem/>
            }


        }

       return <div className="panel">
           <div className="title">美妆翻翻乐</div>
           {main}
           <div className="instruction">
               满额抽奖<br />
               活动时间：11/7(四)-11/17(日) <br />
               活动地点：北京SKP 5F D区<br />
               活动内容:会员当日全馆消费满5000元凭小票即可至活动区域抽奖<br />
               每人每日限参与一次，中奖信息以短信形式发送到手机上，请注意查收，奖品以实物为准。
           </div>
       </div>
    }

    componentDidMount() {
        // Client.logout()

    }



    onClickCards(){

    }
}
export default Panel;