import {createGlobalState} from "react-hooks-global-state";

function resetOperactionGlobalState(){
    setGlobalState('requestId','0')
    setGlobalState('redeemCode','0')
    setGlobalState('blockType','0')
    setGlobalState('productId','0')
    setGlobalState('productName','谢谢惠顾')
    setGlobalState('vCode','0')
    setGlobalState('product', {})
}

function resetGlobalState(){
    setGlobalState('username','anyone')
    setGlobalState('access','0')
    setGlobalState('requestId','0')
    setGlobalState('redeemCode','0')
    setGlobalState('blockType','0')
    setGlobalState('productId','0')
    setGlobalState('productName','谢谢惠顾')
    setGlobalState('modalShow',false)
    setGlobalState('waitShow',false)
    setGlobalState('cardAutoPlay',true)
    setGlobalState('panelShow','login')
    setGlobalState('vCode','0')
    setGlobalState('product', {})
    setGlobalState('token', {})

}
const {setGlobalState, useGlobalState,getGlobalState, subscribe} = createGlobalState({
    username:'anyone',
    access:'0',
    requestId:'0',
    redeemCode:'0',
    blockType:'0',
    productId:'0',
    productName:'谢谢惠顾',
    modalShow:false,
    waitShow:false,
    cardAutoPlay:false,
    panelShow:'login',
    vCode:'',
    product:{},
    token:''
})

// const {updateCurrentComponentState}= (key, value, currentComp)=>{
//     currentComp.state[key] = value
// }

export {setGlobalState, useGlobalState, getGlobalState,subscribe,resetGlobalState,resetOperactionGlobalState }