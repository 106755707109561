import React from "react"
import './Msg.css'
import logoThin from "./img/logo_thin.svg";

import {getGlobalState, resetOperactionGlobalState, setGlobalState} from "./GlobalState";

class Msg extends React.Component {
    constructor(props) {
        super(props);
        let product = getGlobalState('product')
        this.state = {
            vCodeBtnText: "发送验证码",
            vCodeBtnDisabled: false,
            submitBtnDisabled: true,
            submitBtnClass: 'disabled',
            msgText: product.msgText,
            msgTitle: '',
            productId: product.productId,
            productName:product.productName,
            productDesc:product.productDesc,
            cardClassName:"card flipped",
            productPic:product.productPic,
            qrCode: product.qrCode
        }
    }

    render() {
        return <div className="messageWrap">
            <div className="deckFull">
                <div className={this.state.cardClassName}>
                    <div className='front face superlight'>
                        {/*<section className="modal hidden">*/}
                        {/*    <div className="formPhone">*/}
                        {/*        <img className="uplogo" src={logoThin} alt="SKP"/>*/}
                        {/*        <div className="login-form">*/}
                        {/*            <input onChange={this.inputMobile.bind(this)} type="text" placeholder="手机号"/>*/}
                        {/*            <button disabled={this.state.vCodeBtnDisabled} className="verifyCode"*/}
                        {/*                    onClick={this.sendVcode.bind(this)}>{this.state.vCodeBtnText}</button>*/}
                        {/*            <input onChange={this.inputVcode.bind(this)} type="text" placeholder="验证码"/>*/}
                        {/*            <button className={this.state.submitBtnClass}*/}
                        {/*                    disabled={this.state.submitBtnDisabled}*/}
                        {/*                    onClick={this.verifyMobile.bind(this)}>提交*/}
                        {/*            </button>*/}
                        {/*            <button className='cancelbtn'*/}
                        {/*                    onClick={this.cancel.bind(this)}>取消*/}
                        {/*            </button>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <p>*/}
                        {/*            {this.state.msgText}*/}
                        {/*        </p>*/}
                        {/*    </div>*/}

                        {/*    /!*<button className="btn">提交</button>*!/*/}
                        {/*</section>*/}
                    </div>
                    <div className="back face superlight">
                        <div className="productSide">
                            <div className = 'productWrap'>
                                {this.state.productPic&& <img className="productImg" src={this.state.productPic} alt="SKP"/>}
                                <div className='productName'>{this.state.productName}</div>
                                <div className='productDesc'>{this.state.productDesc}</div>
                                {this.state.qrCode &&<img className="msgQrCode" src={this.state.qrCode} alt="二维码"/>}
                                    <button className = "okbtn" onClick={this.onClickOk.bind(this)} >好</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="messageBlock"></div>
        </div>
    }

    // inputMobile(event) {
    //     this.setState({
    //         'mobile': event.target.value
    //     })
    //
    // }
    //
    // inputVcode(event) {
    //     this.setState({
    //         'vCode': event.target.value
    //     })
    //
    // }
    //
    // sendVcode() {
    //     this.setState({
    //         'msgText': ''
    //     })
    //     let mobile = this.state.mobile
    //     return Client.sendVCode(mobile).then(data => {
    //         // console.log(data)
    //         if (data.ErrorCode === 0) {
    //             //提示已经发送
    //             this.setState({
    //                 'msgText': '验证码已成功发送',
    //                 'submitBtnDisabled': false,
    //                 'submitBtnClass': '',
    //             })
    //         }
    //         if (data.ErrorCode !== 0) {
    //             throw Error('验证码发送错误，请稍后再试')
    //         }
    //     }).catch(error => {
    //         this.setState({
    //             'msgText': error.message
    //         })
    //     })
    //
    //
    // }
    //
    // verifyMobile() {
    //     this.setState({
    //         'msgText': ''
    //     })
    //     // let mobile = this.state.mobile
    //     let vCode = this.state.vCode
    //     let requestId = Client.getRequestId()
    //     return Client.verifyVCode(requestId, vCode).then(data => {
    //         if (data.ErrorCode === 0) {
    //             this.setState({
    //                 'msgText': '兑换成功',
    //                 'productPic':'data:image/jpeg;base64,'+data.ProductPic,
    //                 'productId': data.ProductID,
    //                 'productName':data.ProductName,
    //                 'productDesc':data.ProductDesc,
    //                 'cardClassName':"card flipped"
    //             })
    //         }
    //         if (data.ErrorCode === 1) {
    //             throw Error('验证码验证失败')
    //         }
    //         if (data.ErrorCode === 2) {
    //             throw Error('已经兑换过了')
    //         }
    //         if (data.ErrorCode === 3) {
    //             throw Error('在活动日期内')
    //         }
    //         if (data.ErrorCode > 3) {
    //             throw Error('兑换错误，请稍后再试')
    //         }
    //     }).catch(error => {
    //         this.setState({
    //             'msgText': error.message
    //         })
    //     })
    //
    // }

    resetMsg(){
        this.setState({
            vCodeBtnText: "发送验证码",
            vCodeBtnDisabled: false,
            submitBtnDisabled: true,
            submitBtnClass: 'disabled',
            msgText: '',
            msgTitle: '',
            productId: '',
            productName:'谢谢惠顾',
            cardClassName:'card clickcard',
            productDesc:'',
            productPic:logoThin
        })
    }
    onClickOk(){
        console.log('ok')
        setGlobalState('modalShow',false)
        this.resetMsg()
        resetOperactionGlobalState()
        setGlobalState('cardAutoPlay',true)
        setGlobalState('panelShow','showCards')
    }
    cancel(){
        setGlobalState('modalShow',false)
        this.resetMsg()
        setGlobalState('cardAutoPlay',true)
    }


}

export default Msg;