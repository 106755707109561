import Config from "./Config";
import md5 from "md5";
import React from "react";
import {getGlobalState, resetGlobalState, setGlobalState} from "./GlobalState";

class Client extends React.Component {


    static request(api, array) {

        const requestOptions = {
            method: 'POST',
            headers: Config.requestHeaders,
            body: JSON.stringify(array),
        };
        var obj
        return fetch(Config.domain + api, requestOptions).then(response => {

                return new Promise(
                    (resolve, reject) => {
                        if (!response.ok) {
                            reject(Error(response.statusText))
                        }
                        if (response.ok) {
                            response.json().then(
                                data => {
                                    if (!data.scriptResult) {
                                        reject(Error("data.scriptResult"))
                                    }
                                    if (data.scriptResult.code !== 0) {
                                        reject(Error("data.scriptResult.code"))
                                    }
                                    if (!data.scriptResult.resultParameter) {
                                        reject(Error("data.scriptResult.resultParameter"))
                                    }
                                    obj = JSON.parse(data.scriptResult.resultParameter)
                                    // console.log(obj)
                                    if (obj.ErrorCode !== 0) {
                                        reject(Error("data.scriptResult.resultParameter.Errocode"))
                                    }
                                    if (obj.ErrorCode === 0) {
                                        resolve(obj)
                                    }
                                }
                            )
                        }
                    })
            }
        ).catch(error => {
            console.log(error)
            return new Promise((resolve, reject) => {
                resolve(obj)
            })
        })
    }

    static loadSavedLogin() {
        let accessData = localStorage.getItem('access')
        let username = localStorage.getItem('username')
        let token = localStorage.getItem('token')
        setGlobalState('access', accessData)
        setGlobalState('username', username)
        setGlobalState('token', token)
    }

    static isLogin() {

        let accessData = this.getAccessData()
        let username = this.getUsername()
        if (accessData !==0 || username !=='guest') {
            return true
        } else {
            return false
        }
    }

    static login(username, pw) {
        let md5UsernamePw = md5(username + '_' + pw).toUpperCase()
        let option = {
            'scriptParameterValue': md5UsernamePw
        }

        return this.request(Config.api.login, option).then(data => {
            this.saveAccessData(data.Access)
            this.saveToken(md5UsernamePw)
            this.saveUsername(username)
            return new Promise(resolve => {
                resolve(data)
            })
        })
    }

    static logout() {
        resetGlobalState()
        this.saveAccessData(0)
        this.saveUsername('guest')
        this.saveToken('')
        return true
    }

    static saveAccessData(data) {
        localStorage.setItem('access', data)
        return setGlobalState('access', data)
    }

    static getAccessData() {
        return getGlobalState('access')

        // return localStorage.getItem('access')
    }

    static saveUsername(data) {
        localStorage.setItem('username',data)
        return setGlobalState('username', data)

        // return localStorage.setItem('username', data)
    }

    static getUsername() {
        return getGlobalState('username')
    }

    static saveToken(data) {
        localStorage.setItem('token',data)
        return setGlobalState('token', data)

        // return localStorage.setItem('username', data)
    }

    static getToken() {
        return getGlobalState('token')
    }

    // static isAccessible(){
    //     let access = getGlobalState('access')
    //     if(access!==1 ||access!==2 ||access!==3){
    //         return new Promise((resolve,reject) => {
    //             reject(Error("access"))
    //         })
    //     }
    //     return new Promise((resolve,reject) => {
    //         resolve(access)
    //     })
    // }

    static sendVCode(mobile, token) {

        let option = {
            'scriptParameterValue': mobile+'_'+token
        }
        return this.request(Config.api.sendVcode, option).then(data => {
            this.saveRequestId(data.RequestID)
            return new Promise(resolve => {
                resolve(data)
            })
        })
    }
    static preVerifyVCode(requestId, verficationCode,token){
        let option = {
            'scriptParameterValue': requestId + '_' + verficationCode+'_'+token
        }
        return this.request(Config.api.verifyVcodeP, option).then(data => {
            return new Promise(resolve => {
                resolve(data)
            })
        })
    }

    static verifyVCode(requestId, verficationCode,token) {
        let option = {
            'scriptParameterValue': requestId + '_' + verficationCode+'_'+token
        }
        return this.request(Config.api.verifyVcode, option).then(data => {
            this.saveRedeemCode(data.RedeemCode)
            this.saveBlockType(data.BlockType)
            this.saveProductId(data.ProductID)
            this.saveProductName(data.ProductName)
            return new Promise(resolve => {
                resolve(data)
            })
        })
    }

    static redeem(redeemCode, token) {

        let option = {
            'scriptParameterValue': redeemCode + '_' + token
        }
        return this.request(Config.api.redeem, option)
    }

    static showRedeem(redeemCode) {
        let option = {
            'scriptParameterValue': redeemCode
        }
        return this.request(Config.api.showRedeem, option)
    }

    static getRequestId() {
        return getGlobalState('requestId')
        // return localStorage.getItem('requestId')
    }

    static saveRequestId(data) {
        return setGlobalState('requestId', data)

        // return localStorage.setItem('requestId', data)
    }

    static getRedeemCode() {
        return getGlobalState('redeemCode')
        // return localStorage.getItem('redeemCode')

    }

    static saveRedeemCode(data) {
        return setGlobalState('redeemCode', data)

        // return localStorage.setItem('redeemCode', data)

    }

    static getBlockType() {
        return getGlobalState('blockType')

        // return localStorage.getItem('blockType')

    }

    static saveBlockType(data) {
        return setGlobalState('blockType', data)

        // return localStorage.setItem('blockType', data)
    }

    static getProductId() {
        return getGlobalState('productId')
        // return localStorage.getItem('productId')

    }

    static saveProductId(data) {
        return setGlobalState('productId', data)

        // return localStorage.setItem('productId', data)
    }

    static getProductName() {
        return getGlobalState('productName')

        // return localStorage.getItem('productName')

    }

    static saveProductName(data) {
        return setGlobalState('productName', data)

        // return localStorage.setItem('productName', data)

    }


}

export default Client;