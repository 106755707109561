import './Draw.css'
import React from "react"
import logoThin from "./img/logo_thin.svg";
import Client from "./Client";
import {resetOperactionGlobalState, setGlobalState} from "./GlobalState";

class Draw extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            mobile:'',
            mobileValid:'',
            vCode:'',
            vCodeValid:false,
            vCodeBtnText: "发送验证码",
            vCodeBtnDisabled: true,
            vCodeBtnClass: 'disabled',
            vCodeTimer:'',
            submitBtnText:'提交',
            submitBtnDisabled: true,
            submitBtnClass: 'disabled',
            submitTimer:'',
            msgText: '',
            msgTitle: '',

            productId: '',
            productName:'谢谢惠顾',
            productDesc:'',
            cardClassName:'card clickcard',
            productPic:logoThin,
        }
    }

    render(){
        // setGlobalState('panelShow','drawCards')

        return<section className="draw">
            <div className="formPhone">
                {/*<img className="uplogo" src={logoThin} alt="SKP"/>*/}
                <div className="login-form">
                    <input onChange={this.inputMobile.bind(this)} type="tel" placeholder="手机号"/>
                    <button disabled={this.state.vCodeBtnDisabled} className={this.state.vCodeBtnClass}
                            onClick={this.sendVcode.bind(this)}>{this.state.vCodeBtnText}</button>
                    <input onChange={this.inputVcode.bind(this)} type="tel" placeholder="验证码"/>
                    <button className={this.state.submitBtnClass}
                            disabled={this.state.submitBtnDisabled}
                            onClick={this.verifyMobile.bind(this)}>{this.state.submitBtnText}
                    </button>
                    <button className='cancelbtn'
                            onClick={this.cancel.bind(this)}>取消
                    </button>

                </div>
            </div>
            <div>
                <p className='msText'>
                    {this.state.msgText}
                </p>
            </div>

        </section>
    }


    inputMobile(event) {
        if(event.target.value.length===11){
            this.setState({
                'vCodeBtnDisabled':false,
                'vCodeBtnClass': '',
                'mobile': event.target.value,
                'mobileValid':true
            })
        }
        if (event.target.value.length!==11) {
            // console.log('请输入正确的手机号')
            this.setState({
                'vCodeBtnDisabled': true,
                'vCodeBtnClass': 'disabled',
                'mobileValid':false,
                'mobile':''
            })
        }
    }

    inputVcode(event) {
        // let mobile = this.state.mobile
        if(event.target.value.length===4){
            this.setState({
                'vCode': event.target.value,
                'submitBtnDisabled':false,
                'submitBtnClass': '',
                'vCodeValid':true
            })
        }
        if (event.target.value.length!==4) {
            // console.log('请输入4数的验证码')
            this.setState({
                'vCode':"",
                'submitBtnDisabled': true,
                'submitBtnClass': 'disabled',
                'vCodeValid':false
            })
        }

    }

    sendVcode() {
        this.setState({
            'msgText': '',
            'submitBtnDisabled': true,
            'vCodeBtnDisabled':true
        })
        let mobileValid = this.state.mobileValid
        let token = Client.getToken()
        let mobile = this.state.mobile

        if(!mobileValid){
            this.setState({
                'msgText':'请输入正确的手机号码'
            })
            return
        }

        this.setState({
            'submitBtnDisabled': true,
            'vCodeBtnDisabled':true,
            'vCodeBtnClass':'disabled',
            'submitBtnClass':'disabled'
        })
        this.vCodeTimer()
        return Client.sendVCode(mobile,token).then(data => {

            // console.log(data)
            if (data.ErrorCode === 0) {
                //提示已经发送
                this.setState({
                    'msgText': '验证码已成功发送，请输入验证码',
                    // 'submitBtnDisabled': false,
                    // 'submitBtnClass': '',
                })
            }
            if (data.ErrorCode === 2) {
                throw Error('验证码获取过于频繁请等待('+data.ErrorCode+')')
            }
            if (data.ErrorCode === 10) {
                throw Error('今日已经抽过奖('+data.ErrorCode+')')
            }
        }).catch(error => {
            this.setState({
                'msgText': error.message
            })
        })
    }

    vCodeTimer(){
        let time = 30; //倒计时时间
        let validCode= this.state.mobileValid
        let that = this
        if (validCode) {
            // that.setState({
            //     'mobileValid':false
            // })
            let vCodeTimer = setInterval(function() {
                time--;
                that.setState({
                    'vCodeBtnText':'已经发送('+time + "秒)",
                    'vCodeBtnClass':'disabled',
                    'vCodeBtnDisabled':true
                })
                if (time === 0) {
                    clearInterval(vCodeTimer);
                    that.setState({
                        'vCodeBtnText':'重新获取',
                        'vCodeBtnClass':'',
                        'vCodeBtnDisabled':false
                    })
                    // that.setState({
                    //     'mobileValid':true
                    // })
                }
            }, 1000);
        }
    }

    verifyMobile() {

        let that = this
        that.setState({
            'submitBtnText':'已提交',
            'submitBtnClass':'disabled',
            'submitBtnDisabled':true,
            'msgText':'已提交'
        })
        let vCode = this.state.vCode
        let requestId = Client.getRequestId()
        let token = Client.getToken()
        let mobileValid = this.state.mobileValid
        let vcodeValid = this.state.vCodeValid
        if(!vcodeValid){
            this.setState({
                'msgText':'请输入正确的验证码'
            })
            return
        }
        if(!mobileValid){
            this.setState({
                'msgText':'请输入正确的手机号码'
            })
            return
        }

        that.submitTimer()
        return Client.preVerifyVCode(requestId, vCode, token).then(data => {
            if (data.ErrorCode === 0) {
                this.setState({
                    'msgText':'验证通过'
                })
                setGlobalState('vCode',that.state.vCode)
                setGlobalState('panelShow','drawCards')
            }
            if (data.ErrorCode === 1) {
                throw Error('验证失败')
            }
            if (data.ErrorCode === 2) {
                throw Error('已抽过奖了')
            }
            if (data.ErrorCode === 4) {
                throw Error('验证码过期')
            }
            if (data.ErrorCode === 5) {
                throw Error('没有权限')
            }
        }).catch(error => {
                this.setState({
                    'msgText': error.message
                })
            })


    }


    submitTimer(){
        let time = 5; //倒计时时间
        let validMobile= this.state.mobileValid
        let validCode= this.state.vCodeValid
        let that = this
        if (validCode && validMobile) {
            let submitTimer = setInterval(function() {
                time--;
                that.setState({
                    'submitBtnText':'已提交（'+time + "秒）",
                    'submitBtnClass':'disabled',
                    'submitBtnDisabled':true
                })
                if (time === 0) {
                    clearInterval(submitTimer);
                    that.setState({
                        'submitBtnText':'提交',
                        'submitBtnClass':'',
                        'submitBtnDisabled':false
                    })

                }
            }, 1000);
        }
    }

    resetMsg(){
        this.setState({
            mobile:'',
            mobileValid:'',
            vCode:'',
            vCodeValid:false,
            vCodeBtnText: "发送验证码",
            vCodeBtnDisabled: true,
            vCodeBtnClass: 'disabled',
            vCodeTimer:'',
            submitBtnText:'提交',
            submitBtnDisabled: true,
            submitBtnClass: 'disabled',
            submitTimer:'',
            msgText: '',
            msgTitle: '',

            productId: '',
            productName:'谢谢惠顾',
            productDesc:'',
            cardClassName:'card clickcard',
        })
    }
    onClickOk(){
        setGlobalState('modalShow',false)
        this.resetMsg()
        resetOperactionGlobalState()
        setGlobalState('cardAutoPlay',true)
    }
    cancel(){
        setGlobalState('panelShow','userCenter')
        // setGlobalState('modalShow',false)
        // this.resetMsg()
        // setGlobalState('cardAutoPlay',true)
    }





}
export default Draw