import React from "react"
import './Login.css'
import Client from "./Client";
import {setGlobalState} from "./GlobalState";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            msgText:''
        }
    }

    render() {
        return <div className="login-page">
            <div className="form">
                <div className="login">
                    <div className="login-header">
                        <h3>登录</h3>
                    </div>
                </div>
                <div className="login-form">
                    <input onChange={this.inputUsername.bind(this)} type="text" placeholder="用户名"/>
                    <input onChange={this.inputPw.bind(this)} type="password" placeholder="密码"/>
                    <button onClick={this.login.bind(this)}>登录</button>
                </div>
                {this.state.msgText &&<p className='msText'>
                    {this.state.msgText}
                </p>}
            </div>
        </div>
    }

    inputUsername(event){
        this.setState({
            'username':event.target.value
        })
    }
    inputPw(event){
        this.setState({
            'password':event.target.value
        })
    }
    login(){
        let username = this.state.username
        let password = this.state.password
        if(!username || !password){
            this.setState({
                'msgText': '用户名和密码不能为空'
            })
            return
        }
        Client.login(username, password).then(data => {
            if (data.ErrorCode === 1) {
                throw Error('账号或密码错误')
            }
        }).catch(e=>{
            this.setState({
                'msgText':e.message
            })
            setGlobalState('panelShow','login')
        })
    }
}

export default Login;