import React from "react"
import './Cards.css'
import logoThin from "./img/logo_thin.svg";
import {getGlobalState, setGlobalState, subscribe} from "./GlobalState";
import Client from "./Client";
import p1 from "./img/items/1.jpg";
import p2 from "./img/items/2.jpg";
import p3 from "./img/items/3.jpg";
import p4 from "./img/items/4.jpg";
import p5 from "./img/items/5.jpg";
import p6 from "./img/items/6.jpg";
import p7 from "./img/items/7.jpg";
import p8 from "./img/items/8.jpg";
import p9 from "./img/items/9.jpg";

class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.cards = React.createRef()
        let intervalId = setInterval(this.cardPlay.bind(this), 2000)
        setGlobalState('cardAutoPlay',true)
        this.state = {
            cards: [p1,
                p2,
                p3,
                p4,
                p5,
                p6,
                p7,
                p8,
                p9],
            cardPlay: [intervalId],
            clicked: false,
            productPic: '',
            msgText:''
        }


    }

    // componentWillMount() {
    //
    // }


    render() {
        subscribe('cardAutoPlay', this.onCardAutoPlayChange.bind(this))

        // let array = [0, 1, 2, 3, 4, 5, 6, 7, 8,]
        let array = this.state.cards

        return<div>
            <div className="cards"  ref={this.cards}>
                {array.map((itemPic, index) => {
                    var className
                    if (index % 2 === 0) {
                        className = "front face dark"
                    }

                    if (index % 2 !== 0) {
                        className = "front face light"
                    }

                    return<div className="deck" onClick={this.onClickCards.bind(this)} key={index}>
                            <div className="card clickcard">
                                <div className={className}>
                                    <img className="card-logo" src={logoThin} alt="SKP"/>
                                </div>
                                <div className="back face white">
                                    {this.state.productPic ?
                                        <img className="product" src={this.state.productPic} alt="SKP"/> :
                                        <img className="product" src={itemPic} alt="SKP"/>}
                                </div>
                            </div>
                        </div>
                })}
            </div>
            <p className='msTextCard'>{this.state.msgText}</p>
        </div>
    }

    onCardAutoPlayChange(value){
        if(value){
            this.startCardPlay()
        }
        if(!value){
            this.stopCardPlay()
        }
    }

    cardPlay() {
        this.resetCards()

        let index = Math.floor(Math.random() * this.state.cards.length);
        if(this.cards.current){
            this.cards.current.childNodes[index].firstChild.className = "card flipped"

        }
    }

    onClickCards(e) {
        let that = this
        if (that.state.clicked) {
            return
        }
        that.setState({
            'clicked': true,
            'msgText':'正在抽奖...'
        })
        // console.log('after')

        setGlobalState('cardAutoPlay',false)
        // this.stopCardPlay()

        that.verifyMobile().then(data => {
                e.target.parentElement.className = 'card flipped'
                that.setState({'clicked': true})

                setTimeout(() => {
                    setGlobalState('modalShow', true)
                }, 1000)
            }
        )


    }

    verifyMobile() {
        // this.setState({
        //     'msgText': ''
        // })
        // let mobile = this.state.mobile
        let vCode = getGlobalState('vCode')
        let requestId = Client.getRequestId()
        let token = Client.getToken()
        return Client.verifyVCode(requestId, vCode, token).then(data => {
            if (data.ErrorCode === 0) {
                this.setState({
                    'productPic': 'data:image/jpeg;base64,' + data.ProductPic
                })
                setGlobalState('product', {
                    'msgText': '兑换成功',
                    'productPic': data.ProductPic?'data:image/jpeg;base64,' + data.ProductPic:'',
                    'productId': data.ProductID,
                    'productName': data.ProductName,
                    'qrCode':  data.QRCode?'data:image/jpeg;base64,' + data.QRCode:'',
                    'productDesc': data.ProductDesc,
                    'cardClassName': "card flipped"
                })
            }
            if (data.ErrorCode === 1) {
                throw Error('验证码验证失败')
            }
            if (data.ErrorCode === 2) {
                throw Error('已经兑换过了')
            }
            if (data.ErrorCode === 3) {
                throw Error('在活动日期内')
            }
            if (data.ErrorCode === 4) {
                throw Error('验证码过期')
            }
            if (data.ErrorCode === 5) {
                throw Error('权限不足')
            }
            if (data.ErrorCode > 5) {
                throw Error('兑换错误，请稍后再试')
            }
        }).catch(error => {
            this.setState({
                'msgText': error.message
            })
            setGlobalState('product', {
                'msgText': error.message,
                'productName': error.message,
                'productDesc': '',
                'cardClassName': "card flipped"
            })
        })

    }

    resetCards(){
        if (!this.cards.current) {
            return
        }
        let childNodes = this.cards.current.childNodes

        for (let i = 0; i < childNodes.length; i++) {
            childNodes[i].firstChild.className = "card clickcard"
        }

    }

    toggleCardPlay() {
        if(!this.state.cardPlay){
          this.startCardPlay()
        }else{
          this.stopCardPlay()
        }
    }
    startCardPlay(){
        this.cardPlay()
        let intervalId = setInterval(this.cardPlay.bind(this), 2000)
        let currentCardPlay = this.state.cardPlay
        if(!currentCardPlay){
            currentCardPlay =[]
        }
        currentCardPlay.push(intervalId)
        this.setState({
            cardPlay: currentCardPlay
        })

    }
    stopCardPlay(){

        let cardplays = this.state.cardPlay
        // console.log(cardplays)
        for(let i = 0 ; i<cardplays.length; i++){
            // console.log(cardplays[i])
            clearInterval(cardplays[i])
        }
        this.setState({
            cardPlay:[]
        })
        this.resetCards()
    }

}

export default Cards;