import React from "react"
import './UserCenter.css'
import {getGlobalState, setGlobalState, subscribe} from "./GlobalState";
import Client from "./Client";


class UserCenter extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            'access':getGlobalState('access')
        }
    }

    render() {
        subscribe('access',this.onAccessChange.bind(this))
        let access = this.state.access
        let drawBtn
        let redeemBtn
        let showBtn
        if(access==='0'||access===0 ||!access){
            setGlobalState('panelShow','login')
            return
        }
        if(access==='1'||access===1){
            showBtn = <button onClick={this.goToShow.bind(this)}>展示</button>
            drawBtn = <button onClick={this.goToDraw.bind(this)}>抽奖</button>
            redeemBtn =<button onClick={this.goToRedeem.bind(this)}>兑换</button>
        }
        if(access==='2'||access===2){
            showBtn = <button onClick={this.goToShow.bind(this)}>展示</button>
            drawBtn = <button onClick={this.goToDraw.bind(this)}>抽奖</button>

        }
        if(access==='3'||access===3){
            redeemBtn =<button onClick={this.goToRedeem.bind(this)}>兑换</button>
        }

        return <div className="user-page">
                <h4>用户中心</h4>
            {showBtn}
            {drawBtn}
            {redeemBtn}
            <button className="cancelbtn" onClick={this.logout.bind(this)}>登出</button>

        </div>
    }
    onAccessChange(value){
        this.setState({'access':value})
    }
    goToShow(){
        setGlobalState('panelShow','showCards')
    }

    goToDraw() {

        setGlobalState('panelShow','draw')
        // setGlobalState('panelShow','drawCards')
    }

    goToRedeem() {
        setGlobalState('panelShow','redeem')
    }

    logout(){
        Client.logout()
    }
}

export default UserCenter