import React from "react"
import './Redeem.css'
import { resetOperactionGlobalState, setGlobalState, subscribe} from "./GlobalState";
import queryString from 'query-string';
import Client from "./Client";
import logoThin from "./img/logo_thin.svg";



class Redeem extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            'redeemMsg':'',
            'redeemCode':'',
            'showRedeemCode':'',
            'showRedeemMsg':'',
            'showRedeemQrCode':logoThin,
            'productDes':'',
            'productName':'',
            'productId':'',
            'redeemTime':'',
            'productImg':logoThin

        }
        // this.handleQuery()
        // //eslint-disable-next-line no-restricted-globals
        // let query = location.search
        // const parsed = queryString.parse(query);
        // if(parsed.redeem){
        //     this.redeem()
        // }
        // if(parsed.showRedeem){
        //     this.showRedeem()
        // }

    }
    componentWillMount() {
        this.handleQuery()
    }

    render() {
        subscribe('redeemCode',this.onRedeemCodeChange.bind(this))
        // //eslint-disable-next-line no-restricted-globals
        // let query = location.search
        // const parsed = queryString.parse(query);
        // let content, redeemCode, showRedeemCode
        let content
        let redeemCode = this.state.redeemCode
        let showRedeemCode = this.state.showRedeemCode
        let login = Client.isLogin()
        let access = Client.getAccessData()

        // if(parsed.redeem){
        //     redeemCode = parsed.redeem
        //
        // }
        // if(parsed.showRedeem){
        //     showRedeemCode = parsed.showRedeem
        // }
        // // this.handleQuery()

        if(!redeemCode && !showRedeemCode && login){
            content = <div className="redeem-page">
                <div className="blockSpace"></div>
                <p>加载中...</p>
                <div className="blockSpace"></div>
                <p>请直接使用诸如微信或 iOS 控制中心二维码扫码工具进行扫码兑换，如果已经扫码请稍等。</p>
                {/*<img alt="扫码示意图"/>*/}
                <button onClick={this.back.bind(this)}>好</button>
            </div>
        }
        if(redeemCode && login){
            content = <div className="redeem-page">
                <p>兑换码：{this.state.redeemCode}</p>
                <h4>{this.state.redeemMsg}</h4>
                <p>兑换时间：{this.state.redeemTime}</p>
                <button onClick={this.back.bind(this)}>好</button>
            </div>
        }
        if(showRedeemCode){
            content = <div className="redeem-page">
                {/*<p>兑换码：{this.state.showRedeemCode}</p>*/}
                <h4>{this.state.showRedeemMsg}</h4>
                {this.state.productImg && <img className="showRedeemProductImg" src={'data:image/jpeg;base64,' + this.state.productImg}
                      alt="SKP"/>}
                <p>{this.state.productName}</p>
                <p>{this.state.productDes}</p>
                {this.state.showRedeemQrCode &&<img className="qrCode" src={'data:image/jpeg;base64,' + this.state.showRedeemQrCode} alt="二维码"/>
                }
                {access >0 &&  <button onClick={this.back.bind(this)}>好</button>}
            </div>
        }

        return<div> {content}</div>

    }

     handleQuery(){
        //eslint-disable-next-line no-restricted-globals
        let query = location.search
        const parsed = queryString.parse(query);
        if(parsed.redeem){
            this.redeem()
        }
        if(parsed.showRedeem){
            this.showRedeem()
        }
    }
    back(){
        resetOperactionGlobalState()
        setGlobalState('panelShow','userCenter')
        // eslint-disable-next-line no-restricted-globals
        let url = location.origin
        window.location=url
    }

    showRedeem(){
        // eslint-disable-next-line no-restricted-globals
        let query = location.search
        const parsed = queryString.parse(query);
        if(!parsed.showRedeem){
            return
        }
        let redeemCode= parsed.showRedeem

        return Client.showRedeem(redeemCode).then(data=>{
            let msg
            let qRCode
            let productDes
            let productId
            let productName
            let productImg
            if(data.ErrorCode===0||data.ErrorCode==='0'||data.ErrorCode===0||data.ErrorCode==='0'){
                msg ='兑换码有效'
                qRCode=data.Base64QRCode
                productDes = data.ProductDesc
                productName = data.ProductName
                productId = data.ProductID
                productImg = data.Productpic
            }
            if(data.ErrorCode===1||data.ErrorCode==='1'||data.ErrorCode===1||data.ErrorCode==='1'){
                msg ='已兑换'
            }
            if(data.ErrorCode===2||data.ErrorCode==='2'||data.ErrorCode===2||data.ErrorCode==='2'){
                msg ='未找到中奖记录'
            }
            if(data.ErrorCode>2){
                msg ='显示兑换错误'
            }
            // this.state ={
            //     'showRedeemCode':parsed.showRedeem,
            //     'showRedeemMsg':msg,
            //     'showRedeemQrCode':qRCode,
            //     'productDes':productDes,
            //     'productName':productName,
            //     'productId':productId
            // }
            // console.log(this.state)
            this.setState({
                'showRedeemCode':parsed.showRedeem,
                'showRedeemMsg':msg,
                'showRedeemQrCode':qRCode,
                'productDes':productDes,
                'productName':productName,
                'productId':productId,
                'productImg':productImg
            })

            }
        )
    }

    redeem(){

        // eslint-disable-next-line no-restricted-globals
        let query = location.search
        const parsed = queryString.parse(query);
        if(!parsed.redeem){
            return
        }
        let redeemCode= parsed.redeem
        let token = Client.getToken()
        return Client.redeem(redeemCode,token).then(
            data=>{
                // console.log(data)
                let msg
                let redeemTime
                if(data.ErrorCode ==='0'||data.ErrorCode ===0||data.ErrorCode ==='0'||data.ErrorCode ===0){
                    msg ='兑换成功'
                    redeemTime = data.RedeemTime
                }
                if(data.ErrorCode ==='1'||data.ErrorCode ===1||data.ErrorCode ==='1'||data.ErrorCode ===1){
                    msg = '权限不足'
                }
                if(data.ErrorCode ==='2'||data.ErrorCode ===2||data.ErrorCode ==='2'||data.ErrorCode ===2){
                    msg ='兑换码不存在'
                }
                if(data.ErrorCode ==='3'||data.ErrorCode ===3||data.ErrorCode ==='3'||data.ErrorCode ===3){
                    msg = '已经兑换过了'
                    redeemTime = data.RedeemTime
                }
                setGlobalState('redeemCode',parsed.redeem)
                this.setState({
                    'redeemMsg': msg,
                    'redeemCode': parsed.redeem,
                    'redeemTime':redeemTime
                })
            }
        )
    }


    onRedeemCodeChange(value){
        // this.state={
        //     redeemCode:value,
        //     redeemMsg: this.state.redeemMsg
        // }
        this.setState({'redeemCode':value})

    }
}

export  default  Redeem
