import React from "react"
import './ShowCards.css'
import logoThin from "./img/logo_thin.svg";
import p1 from "./img/items/1.jpg";
import p2 from "./img/items/2.jpg";
import p3 from "./img/items/3.jpg";
import p4 from "./img/items/4.jpg";
import p5 from "./img/items/5.jpg";
import p6 from "./img/items/6.jpg";
import p7 from "./img/items/7.jpg";
import p8 from "./img/items/8.jpg";
import p9 from "./img/items/9.jpg";
import {setGlobalState, subscribe} from "./GlobalState";

class ShowCards extends React.Component {
    constructor(props) {
        super(props);
        this.cards = React.createRef()
        let intervalId = setInterval(this.cardPlay.bind(this), 2000)
        setGlobalState('cardAutoPlay',true)
        this.state = {
            cards: [p1,
                p2,
                p3,
                p4,
                p5,
                p6,
                p7,
                p8,
                p9],
            cardPlay: [intervalId]
        }


    }

    render() {
        subscribe('cardAutoPlay', this.onCardAutoPlayChange.bind(this))
        return <div className="cards" ref={this.cards}>

            {this.state.cards.map((itemPic, index) => {
                var className
                if (index % 2 === 0) {
                    className = "front face dark"
                }

                if (index % 2 !== 0) {
                    className = "front face light"
                }

                    return <div className="deck" onClick={this.onClickCards.bind(this)}  key={index}>
                        <div className="card clickcard">
                            <div className={className}>
                                <img className="card-logo" src={logoThin} alt="SKP"/>
                            </div>
                            <div className="back face white">
                                <img className="product" src={itemPic} alt="SKP"/>
                            </div>
                        </div>
                    </div>

            })}
        </div>
    }

    onCardAutoPlayChange(value){
        if(value){
            this.startCardPlay()
        }
        if(!value){
            this.stopCardPlay()
        }
    }

    cardPlay() {
        this.resetCards()

        let index = Math.floor(Math.random() * this.state.cards.length);
        if(this.cards.current){
            this.cards.current.childNodes[index].firstChild.className = "card flipped"

        }
    }

    onClickCards(e){

        // setGlobalState('modalShow',true)
        // setGlobalState('cardAutoPlay',false)
    }

    resetCards(){
        if (!this.cards.current) {
            return
        }
        let childNodes = this.cards.current.childNodes

        for (let i = 0; i < childNodes.length; i++) {
            childNodes[i].firstChild.className = "card clickcard"
        }


    }

    // toggleCardPlay() {
    //     if(!this.state.cardPlay){
    //       this.startCardPlay()
    //     }else{
    //       this.stopCardPlay()
    //     }
    // }
    startCardPlay(){
        this.cardPlay()
        let intervalId = setInterval(this.cardPlay.bind(this), 2000)
        let currentCardPlay = this.state.cardPlay
        if(!currentCardPlay){
            currentCardPlay =[]
        }
        currentCardPlay.push(intervalId)
        this.setState({
            cardPlay: currentCardPlay
        })

    }
    stopCardPlay(){

        let cardplays = this.state.cardPlay
        // console.log(cardplays)
        for(let i = 0 ; i<cardplays.length; i++){
            // console.log(cardplays[i])
            clearInterval(cardplays[i])
        }
        this.setState({
            cardPlay:[]
        })
        this.resetCards()
    }

}

export default ShowCards;