import './App.css';
import logoThick from './img/logo_thick.svg';
import Panel from './Panel'
import Msg from "./Msg";
import React from "react"
import {getGlobalState, setGlobalState, subscribe} from "./GlobalState";
import Client from './Client'
import queryString from "query-string";


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            msgShow: getGlobalState('modalShow'),
            waitShow: true,
        }
        Client.loadSavedLogin()
        this.handleQuery()


    }


    render() {
        subscribe('modalShow', this.onShowChange.bind(this))
        subscribe('waitShow', this.onWaitChange.bind(this))
        return (
            <div className="App">

                {this.state.msgShow && <Msg/>}

                <div className="container">
                    <div onClick={this.showUserCenter.bind(this)}>
                        <img className="App-logo" src={logoThick} alt="SKP"/>
                    </div>

                    <Panel/>
                </div>
            </div>
        )
    }


    showUserCenter() {
        setGlobalState('panelShow', 'userCenter')
    }

    onWaitChange(show){
        this.setState({
            waitShow: show
        })
    }

    onShowChange(show) {
        this.setState({
            msgShow: show
        })
    }
    handleQuery(){
        //eslint-disable-next-line no-restricted-globals
        let query = location.search
        const parsed = queryString.parse(query);
        if(parsed.redeem){
           setGlobalState('panelShow','redeem')
        }
        if(parsed.showRedeem){
            setGlobalState('panelShow','showRedeem')

        }
    }

}


export default App;
