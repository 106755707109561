
class Config{
    static domain ='/fmi/odata/v4/luckydraw/'
    //static domain ='https://skp.heysimple.cn/fmi/odata/v4/luckydraw/'
    static requestHeaders = {
        'Authorization':"Basic YWRtaW46a2FvZGlndWE=",
        'Content-Type':"application/json"
        }
    static api={
        'login':'script.login',
        'sendVcode':'script.SendVcode',
        'verifyVcode':'script.VerifyVcode',
        'showRedeem':'script.ShowQRCode',
        'redeem':'script.RedeemCode',
        'verifyVcodeP':'script.VerifyVcodeP'
    }

}

export default Config;